import { isEmpty, kebabCase, lowerCase } from 'lodash';
import clsx from 'clsx';
import type { ReactElement } from 'react';

import { BaseBlock } from '@/components/base-block';
import { getModelURL } from '@/lib/datocms/utils/get-model-url';
import { Image } from '@/components/image';
import { Link } from '@/components/link';
import type { BannerBlockType } from '@/lib/types';
import { Button } from '@/components/button/styles.css';
import { useRouter } from 'next/router';
import { HUNDRED_YEAR_TAKEOVER } from '@/lib/constants';

const BannerBlock = ({
  anchor,
  title,
  contentPosition,
  bannerHeight = undefined,
  imageTint,
  variant,
  description,
  primaryLink,
  primaryLinkText,
  secondaryLink,
  secondaryLinkText,
  image,
  bannerAlignment,
}: BannerBlockType): ReactElement => {
  const contentPositionClassName = !isEmpty(contentPosition) && `content-${kebabCase(contentPosition)}`;
  const isHeroVariant = variant === 'Hero';
  const bannerHeightClassName = !isHeroVariant && bannerHeight ? `banner-${lowerCase(bannerHeight)}` : null;
  const bannerTypeClassName = isHeroVariant ? 'hero-banner' : 'promotional-banner';
  const bannerPosition = !isEmpty(bannerAlignment) && `position-${lowerCase(bannerAlignment)}`;

  const router = useRouter();

  return (
    <BaseBlock className={clsx('banner', bannerTypeClassName, bannerPosition)} anchor={anchor}>
      <div className={clsx('photo-bg banner-container', contentPositionClassName, bannerHeightClassName, imageTint)}>
        {!isEmpty(image?.data) && <Image {...image} isBackground lazyLoad={!isHeroVariant} />}
        <div className="photo-tint banner-stack-top">
          <div className="text-wrapper">
            <div className="banner-content-container">
              <div className="banner-titles mb-4">
                {!isEmpty(title) && isHeroVariant && (
                  <h1 className="banner-title-wrapper field-bannertitle">{title}</h1>
                )}
                {!isEmpty(title) && !isHeroVariant && (
                  <h2 className="banner-title-wrapper field-bannertitle">{title}</h2>
                )}
                {!isEmpty(description) && <div className="field-bannerdescription">{description}</div>}
              </div>
              {(!isEmpty(primaryLink) || !isEmpty(secondaryLink)) && (
                <div className={clsx('links-container', bannerPosition)}>
                  {!isEmpty(primaryLink) && (
                    <div className="banner-content btn-banner field-bannerlink">
                      <Link
                        className={Button({
                          color: router.route === '/' && HUNDRED_YEAR_TAKEOVER ? 'hundredYearTakeOver' : 'primary', // only apply hundred year takeover style on homepage
                          size: 'md',
                        })}
                        href={getModelURL(primaryLink)}
                        analytics={{ context: 'CTA - Banner', text: primaryLinkText }}
                      >
                        {primaryLinkText}
                      </Link>
                    </div>
                  )}
                  {!isEmpty(secondaryLink) && (
                    <div className="banner-content btn-banner field-bannerlink">
                      <Link
                        className={Button({
                          color: 'outlineWhite',
                          size: 'md',
                        })}
                        href={getModelURL(secondaryLink)}
                        analytics={secondaryLinkText ? { context: 'CTA - Banner', text: secondaryLinkText } : undefined}
                      >
                        {secondaryLinkText}
                      </Link>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </BaseBlock>
  );
};

export { BannerBlock };
